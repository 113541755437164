import { FAQUI } from "modules";

const FAQ = () => {
  return (
    <>
      <FAQUI />
    </>
  );
};

export { FAQ };
