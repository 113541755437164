import { AboutUI } from "modules/about";

const About = () => {
  return (
    <>
      <AboutUI />
    </>
  );
};

export { About };
